import { useScrollContext } from '@app/context/scroll-context';
import LandingPageLaout from '@app/layouts/landingPage';
import { ChevronDown } from 'lucide-react';
import { FAQCollapsibleComponent } from '../common/CollapsibleField';

export default function FAQ() {
    const FAQS = [
        {
            title: 'What is FormURL?',
            content: `FormURL.com is an intuitive platform that allows users to create, customize, and manage online forms for data collection, surveys, registrations, and more. Anyone from individuals to businesses, educators, and organizations can use
                    FormURL.com to create and manage forms easily.`
        },
        { title: 'Do I need coding skills to use FormURL.com?', content: 'Not at all! Our platform is designed for everyone—whether you’re a beginner or a pro. You can build and customize forms using our easy-to-use, no-code interface.' },
        { title: 'Can I customize my form URL?', content: 'Yes! With our upcoming Link Configuration feature, you’ll be able to create custom URLs, set expiration dates, and even add password protection to your form links.' },
        { title: 'Does FormURL.com provide analytics for my forms?', content: 'Absolutely! Our Analytics feature (coming soon) will allow you to track submissions, user interactions, response trends, and much more—all in a real-time dashboard.' },
        { title: ' Can I generate a QR code for my form?', content: 'Yes! With our Custom Domains feature (coming soon), you’ll be able to host your forms on your own domain to maintain branding and credibility.' },
        { title: 'Can I integrate FormURL.com with other tools?', content: 'Yes! We are working on integrations with popular tools like Google Sheets, Zapier, and other third-party apps to help you automate workflows. Stay tuned!' }
    ];

    const { faqsRef } = useScrollContext();

    return (
        <div className="flex flex-col items-center bg-gray-50 bg-how-it-works" ref={faqsRef}>
            <LandingPageLaout>
                <div className="flex flex-col gap-3 text-center text-gray-900">
                    <h1 className="text-sm font-semibold text-primary">FAQ</h1>
                    <h1 className="heading-40-bold">Responses to Common Inquiries</h1>
                </div>
                <div className="flex w-full flex-col items-center gap-4">
                    {[...FAQS].map((f, index) => {
                        return <FAQCollapsibleComponent faq={f} key={index} className={'border border-gray-200 bg-white hover:!shadow-faq-section'} triggerIcon={ChevronDown} />;
                    })}
                </div>
            </LandingPageLaout>
        </div>
    );
}
