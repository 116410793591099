import { Separator } from '@app/shadcn/components/ui/separator';
import { cn } from '@app/shadcn/util/lib';
import { Check } from 'lucide-react';

export const HeroSectionRightPanelCustomizeLink = ({ className }: { className?: string }) => {
    return (
        <div className={cn('flex w-full flex-col gap-4 rounded-3xl border border-hero-sectionBorder bg-white p-4 shadow-hero-section-card md:p-8', className)}>
            <div className="flex flex-col gap-2">
                <h1 className="body-14-medium">Form Link</h1>
                <div className="flex w-full flex-row gap-2 rounded-xl bg-gray-new px-3 py-[14px]">
                    <div className="h-5 w-4/12 rounded-[120px] bg-gray-200" />
                    <div className="h-5 w-6/12 rounded-[120px] bg-gray-200" />
                    <div className="h-5 w-5 rounded-full bg-gray-200" />
                </div>
            </div>
            <div className="flex flex-col gap-2">
                <div className="flex flex-row justify-between">
                    <h1 className="body-14-medium">Customize Link</h1>
                    {/* <div className="flex flex-row items-center gap-1">
                        <Shuffle className="h-3 w-3 text-gray-400" /> <h1 className="text-xs font-medium text-gray-400">Randomize</h1>
                    </div> */}
                </div>
                <div className="flex w-full">
                    <div className="flex h-12 w-5/12 flex-row items-center gap-2 rounded-l-xl bg-gray-new px-3 py-[14px]">
                        <div className="h-full w-full rounded-[120px] bg-gray-200" />
                    </div>
                    <div className="flex h-12 w-full flex-row items-center gap-2 rounded-r-xl border border-gray-200 bg-white px-3 py-[14px]">
                        <div className="h-full w-1/3 rounded-[120px] bg-gray-200" />
                    </div>
                </div>
                <div className="flex flex-row items-center gap-1 text-gray-500">
                    <Check className="h-3 w-3 lg:h-4 lg:w-4" />
                    <h1 className="text-xs font-medium lg:text-sm">This URL link is available</h1>
                </div>
            </div>
            <div className="mt-2 h-10 w-[107px] rounded-3xl bg-gray-100" />
        </div>
    );
};
