import React from 'react';

export const ImportFormIcon = ({ props }: { props?: React.SVGAttributes<any> }) => {
    return (
        <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M11 1.36133V13.3613M11 13.3613L7 9.36133M11 13.3613L15 9.36133M7 3.36133H3C2.46957 3.36133 1.96086 3.57204 1.58579 3.94711C1.21071 4.32219 1 4.8309 1 5.36133V15.3613C1 15.8918 1.21071 16.4005 1.58579 16.7755C1.96086 17.1506 2.46957 17.3613 3 17.3613H19C19.5304 17.3613 20.0391 17.1506 20.4142 16.7755C20.7893 16.4005 21 15.8918 21 15.3613V5.36133C21 4.8309 20.7893 4.32219 20.4142 3.94711C20.0391 3.57204 19.5304 3.36133 19 3.36133H15"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
