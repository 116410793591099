import { useState } from 'react';
import TabContent from './TabPanelContent';
import TabPanelHeader from './TabPanelHeader';

export type TabTypes = 'FORM_MANAGEMENT' | 'LINK_CUSTOMIZATION' | 'ANALYTICS' | 'QR_CODES' | 'CUSTOM_DOMAINS';

export interface IFeatureTab {
    label: string;
    type: TabTypes;
}

const featureTabs: Array<IFeatureTab> = [
    {
        label: 'Form Management',
        type: 'FORM_MANAGEMENT'
    },
    {
        label: 'Link Customization',
        type: 'LINK_CUSTOMIZATION'
    },
    {
        label: 'Analytics',
        type: 'ANALYTICS'
    },
    {
        label: 'QR Codes',
        type: 'QR_CODES'
    },
    {
        label: 'Custom Domains',
        type: 'CUSTOM_DOMAINS'
    }
];

const FeaturesTabPanel = () => {
    const [selectedTab, setTab] = useState<TabTypes>('FORM_MANAGEMENT');

    const handleOnClickTab = (type: TabTypes) => {
        setTab(type);
    };

    return (
        <div className="flex h-full w-full flex-col items-center gap-8">
            <div className="flex flex-row flex-wrap justify-center gap-4">
                {featureTabs.map((tab) => {
                    return <TabPanelHeader key={tab.type} tab={{ ...tab, isTabSelected: tab.type === selectedTab }} handleOnClick={handleOnClickTab} />;
                })}
            </div>
            <TabContent selectedTab={selectedTab} />
        </div>
    );
};

export default FeaturesTabPanel;
