import React from 'react';

export const CustomizeLinkIcon = ({ props }: { props?: React.SVGAttributes<any> }) => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M21.378 16.9875C21.7763 16.5891 22.0001 16.0488 22.0001 15.4855C22.0001 14.9221 21.7763 14.3818 21.378 13.9835C20.9796 13.5851 20.4393 13.3613 19.876 13.3613C19.3126 13.3613 18.7723 13.5851 18.374 13.9835L14.364 17.9955C14.1262 18.2331 13.9522 18.5268 13.858 18.8495L13.021 21.7195C12.9959 21.8055 12.9944 21.8967 13.0166 21.9836C13.0389 22.0704 13.084 22.1496 13.1474 22.213C13.2108 22.2764 13.2901 22.3216 13.3769 22.3438C13.4637 22.3661 13.5549 22.3646 13.641 22.3395L16.511 21.5025C16.8336 21.4083 17.1274 21.2342 17.365 20.9965L21.378 16.9875Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9 17.3613H7C5.67392 17.3613 4.40215 16.8345 3.46447 15.8969C2.52678 14.9592 2 13.6874 2 12.3613C2 11.0352 2.52678 9.76348 3.46447 8.82579C4.40215 7.88811 5.67392 7.36133 7 7.36133H9M15 7.36133H17C18.3261 7.36133 19.5979 7.88811 20.5355 8.82579C21.1244 9.41468 21.5512 10.1353 21.7873 10.9185M8 12.3613H16"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
