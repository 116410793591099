import Image from 'next/image';
import { TabTypes } from './TabPanel';

const TabContent = ({ selectedTab }: { selectedTab: TabTypes }) => {
    function getTabContent() {
        switch (selectedTab) {
            case 'FORM_MANAGEMENT':
                return <FormManagement />;
            case 'LINK_CUSTOMIZATION':
                return <LinkCustomization />;
            case 'ANALYTICS':
                return <Analytics />;
            case 'QR_CODES':
                return <QRCodes />;
            case 'CUSTOM_DOMAINS':
                return <CustomDomains />;
            default:
                return <div>Not Found</div>;
        }
    }

    return <div className="h-full w-full">{getTabContent()}</div>;
};

const CustomDomains = () => {
    return (
        <div className="flex flex-col items-center gap-4 lg:gap-12">
            <div className="relative aspect-[1164/500] h-full w-full">
                <Image fill src={'/svg/link-customization.svg'} alt="custom-domain-feature" />
            </div>
            <h1 className="body-18-regular text-center text-gray-500">Strengthen your brand identity by your own personalized URL Links and Improve credibility, enhance user trust, and create a seamless experience with these branded URLs.</h1>
        </div>
    );
};

const QRCodes = () => {
    return (
        <div className="flex flex-col items-center gap-4 lg:gap-12">
            <div className="relative aspect-[1164/500] h-full w-full">
                <Image fill src={'/svg/qr.svg'} alt="qr-code-feature" />
            </div>
            <h1 className="body-18-regular w-2/3 text-center text-gray-500">Generate unique QR codes for every form, making it seamless for users to access and submit responses on the go. Perfect for events, print media, and instant mobile access.</h1>
        </div>
    );
};

const Analytics = () => {
    return (
        <div className="flex flex-col items-center gap-4 lg:gap-12">
            <div className="relative aspect-[1164/500] h-full w-full">
                <Image fill src={'/svg/analytics.svg'} alt="analytics-feature" />
            </div>
            <h1 className="body-18-regular w-2/3 text-center text-gray-500">Gain deeper insights into your data with real-time analytics. Track form submissions, user engagement, response trends, and conversion rates—all in an easy-to-read dashboard.</h1>
        </div>
    );
};

const LinkCustomization = () => {
    return (
        <div className="flex flex-col items-center gap-4 lg:gap-12">
            <div className="relative aspect-[1164/500] h-full w-full">
                <Image fill src={'/svg/link-customization.svg'} alt="link-customization-feature" />
            </div>
            <h1 className="body-18-regular w-2/3 text-center text-gray-500">Take full control of your form links! Customize your Form Link to your personalized URL links to match your Brand that reflects your brand identity.</h1>
        </div>
    );
};

const FormManagement = () => {
    return (
        <div className="flex flex-col items-center gap-4 lg:gap-12">
            <div className="relative aspect-[1164/500] h-full w-full">
                <Image fill src={'/svg/form-management.svg'} alt="form-management-feature" />
            </div>
            <h1 className="body-18-regular w-2/3 text-center text-gray-500">Effortlessly organize, edit, and manage all your forms in one place. Keep track of responses, update form settings, and streamline your workflow with an intuitive dashboard.</h1>
        </div>
    );
};

export default TabContent;
