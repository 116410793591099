import { useBrowserFingerPrintContext } from '@app/context/browser-fingerprint';
import { useScrollContext } from '@app/context/scroll-context';
import LandingPageLaout from '@app/layouts/landingPage';
import CustomizeUrlCardList from '../ui/customize-url-form-preview-list';
import CustomizeUrlForm from '../ui/forms/customize-url-form';

export default function CustomizeUrl() {
    const { customizeLinkRef } = useScrollContext();

    const { browserFingerPrint: fingerprint } = useBrowserFingerPrintContext();

    return (
        <div ref={customizeLinkRef} id="customize-url" className="relative flex flex-col items-center rounded-t-[48px] bg-customize-link-gradient">
            <div className="absolute inset-0 z-0 bg-customize-link-section bg-contain bg-left-top bg-no-repeat blur-sm"></div>
            <LandingPageLaout className="z-10">
                <div className="flex max-w-[600px] flex-col gap-3 text-center text-white">
                    <h1 className="text-sm font-semibold">CUSTOMIZE LINK</h1>
                    <h1 className="heading-40-bold">Stop Sharing Long, Unattractive Form Links. Use formURL.</h1>
                </div>
                {fingerprint && (
                    <div className="flex w-full flex-col gap-4 px-4 lg:px-0">
                        <div className="rounded-[32px] bg-customize-link-card-border-gradient p-2">
                            <CustomizeUrlForm fingerprint={fingerprint} className="rounded-[32px]" />
                        </div>
                        <CustomizeUrlCardList fingerprint={fingerprint} />
                    </div>
                )}
            </LandingPageLaout>
        </div>
    );
}
