import AnchorLink from '@app/components/common/links/anchor-link';
import Burger from '@app/components/icons/burger';
import { Close } from '@app/components/icons/close';
import LogoIcon from '@app/components/icons/logo';
import environments from '@app/configs/environments';
import { useLoginContext } from '@app/context/login-provider';
import { useScrollContext } from '@app/context/scroll-context';
import { Button } from '@app/shadcn/components/ui/button';
import { cn } from '@app/shadcn/util/lib';
import { useGetUserStatusQuery } from '@app/store/users/api';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

interface INavbarLink {
    title: string;
    targetRef: React.RefObject<HTMLDivElement>;
}

function Navbar() {
    const [isMenuVisible, setMenuVisible] = useState(false);

    const router = useRouter();
    const { data: user } = useGetUserStatusQuery();

    const { scrollToSection, howItWorksRef, featuresRef, faqsRef } = useScrollContext();
    const { loginContext, setLoginContext } = useLoginContext();

    const handleNavigation = (ref: React.RefObject<HTMLDivElement>) => {
        if (router.query.page !== undefined) {
            router.replace('/');
        }
        scrollToSection(ref);
        if (isMenuVisible) {
            setMenuVisible(false);
        }
    };

    const handleClickSignUp = () => {
        setLoginContext({ ...loginContext, isNewUser: true, redirectFromLandingPage: true });
        router.push('/login');
    };

    const handleClickSignIn = () => {
        setLoginContext({ ...loginContext, redirectFromLandingPage: false });
        router.push('/login');
    };

    const NavbarComponents: INavbarLink[] = [
        {
            title: 'How it Works',
            targetRef: howItWorksRef
        },
        {
            title: 'Features',
            targetRef: featuresRef
        },
        {
            title: 'FAQs',
            targetRef: faqsRef
        }
    ];

    return (
        <nav className="border-border-primary relative top-0 !z-30 flex h-[80px] w-full flex-col items-center justify-center border-b bg-white-1">
            <div className={cn('flex w-full max-w-[1440px] items-center justify-between px-6 sm:px-12', isMenuVisible ? 'px-0' : '')}>
                {isMenuVisible ? (
                    <>
                        <div className="flex w-full flex-row items-center justify-between border-b border-gray-200 px-6 pb-6">
                            <AnchorLink href="/" aria-label="navigate to landing page">
                                <LogoIcon />
                            </AnchorLink>
                            <div className="rounded-xl border border-gray-200 p-1">
                                <Close className="h-5 w-5" onClick={() => setMenuVisible(false)} />
                            </div>
                        </div>
                        <div className="absolute top-[68px] flex h-screen w-full flex-col items-center gap-8 bg-white px-5 py-6">
                            <p className="cursor-pointer" onClick={() => handleNavigation(howItWorksRef)}>
                                How It Works
                            </p>
                            <p className="cursor-pointer" onClick={() => handleNavigation(featuresRef)}>
                                Features
                            </p>
                            <p className="cursor-pointer" onClick={() => handleNavigation(faqsRef)}>
                                FAQs
                            </p>
                            <AnchorLink href={environments.FEEDBACK_FORM || ''} target="_blank">
                                Feedback
                            </AnchorLink>
                        </div>
                    </>
                ) : (
                    <>
                        {/* <!-- mobile button goes here --> */}
                        <div className="flex items-center md:hidden">
                            <Burger
                                className="h-6 w-6 cursor-pointer"
                                onClick={() => {
                                    setMenuVisible(!isMenuVisible);
                                }}
                            />
                        </div>
                        <AnchorLink href="/" aria-label="navigate to landing page">
                            <LogoIcon />
                        </AnchorLink>
                        <div className="hidden items-center gap-2 md:flex">
                            {[
                                ...NavbarComponents.map((link, index) => {
                                    return <NavBarLinkComponent targetRef={link.targetRef} title={link.title} key={link.title + index} handleOnClick={handleNavigation} />;
                                })
                            ]}
                        </div>
                        {user ? (
                            <AnchorLink href={'/dashboard'}>
                                <Button size={'md'}>Dashboard</Button>
                            </AnchorLink>
                        ) : (
                            <div className="flex gap-4">
                                <Button onClick={handleClickSignIn} size={'md'}>
                                    Sign in
                                </Button>
                                <Button onClick={handleClickSignUp} size={'md'}>
                                    Sign Up
                                </Button>
                            </div>
                        )}
                    </>
                )}
            </div>
        </nav>
    );
}

export default Navbar;

const NavBarLinkComponent = ({ title, targetRef, handleOnClick }: INavbarLink & { handleOnClick: (ref: React.RefObject<HTMLDivElement>) => void }) => {
    return (
        <div className="body-14-medium cursor-pointer px-2 py-2 text-sm text-gray-900 lg:px-4" onClick={() => handleOnClick(targetRef)}>
            {title}
        </div>
    );
};
