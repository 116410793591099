import { useScrollContext } from '@app/context/scroll-context';
import LandingPageLaout from '@app/layouts/landingPage';
import FeaturesTabPanel from '../pages/landingPage/Features/TabPanel';

export default function Features() {
    const { featuresRef } = useScrollContext();

    return (
        <div className="flex flex-col items-center bg-white" ref={featuresRef}>
            <LandingPageLaout>
                <div className="flex max-w-[470px] flex-col gap-3 text-center text-gray-900">
                    <h1 className="text-sm font-semibold text-primary">COMING SOON</h1>
                    <h1 className="heading-40-bold">About Upcoming Features.</h1>
                    <h2 className="body-18-regular text-gray-500">No more wondering and subscribe to get notified when this awesome feature becomes available.</h2>
                </div>
                <FeaturesTabPanel />
            </LandingPageLaout>
        </div>
    );
}
