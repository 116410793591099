import React from 'react';

function LogoIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="104" height="20" viewBox="0 0 104 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M5.7 0.125C6.4 0.125 7.04167 0.2 7.625 0.35C8.20833 0.483333 8.65833 0.616666 8.975 0.749999L8.25 3.725C7.91667 3.575 7.55 3.46667 7.15 3.4C6.76667 3.31667 6.40833 3.275 6.075 3.275C5.625 3.275 5.24167 3.34167 4.925 3.475C4.625 3.59167 4.38333 3.75833 4.2 3.975C4.03333 4.19167 3.90833 4.45 3.825 4.75C3.75833 5.05 3.725 5.375 3.725 5.725V6.375H8.325V9.475H3.725V19.525H0V5.675C0 3.975 0.475 2.625 1.425 1.625C2.39167 0.625 3.81667 0.125 5.7 0.125Z"
                fill="#FF9B0A"
            />
            <path
                d="M22.6516 12.925C22.6516 13.9583 22.5016 14.9083 22.2016 15.775C21.9016 16.625 21.4682 17.3583 20.9016 17.975C20.3349 18.575 19.6516 19.0417 18.8516 19.375C18.0682 19.7083 17.1849 19.875 16.2016 19.875C15.2349 19.875 14.3516 19.7083 13.5516 19.375C12.7682 19.0417 12.0932 18.575 11.5266 17.975C10.9599 17.3583 10.5182 16.625 10.2016 15.775C9.8849 14.9083 9.72656 13.9583 9.72656 12.925C9.72656 11.8917 9.8849 10.95 10.2016 10.1C10.5349 9.25 10.9849 8.525 11.5516 7.925C12.1349 7.325 12.8182 6.85833 13.6016 6.525C14.4016 6.19167 15.2682 6.025 16.2016 6.025C17.1516 6.025 18.0182 6.19167 18.8016 6.525C19.6016 6.85833 20.2849 7.325 20.8516 7.925C21.4182 8.525 21.8599 9.25 22.1766 10.1C22.4932 10.95 22.6516 11.8917 22.6516 12.925ZM18.8516 12.925C18.8516 11.775 18.6182 10.875 18.1516 10.225C17.7016 9.55833 17.0516 9.225 16.2016 9.225C15.3516 9.225 14.6932 9.55833 14.2266 10.225C13.7599 10.875 13.5266 11.775 13.5266 12.925C13.5266 14.075 13.7599 14.9917 14.2266 15.675C14.6932 16.3417 15.3516 16.675 16.2016 16.675C17.0516 16.675 17.7016 16.3417 18.1516 15.675C18.6182 14.9917 18.8516 14.075 18.8516 12.925Z"
                fill="#FF9B0A"
            />
            <path
                d="M33.4621 9.675C33.1288 9.59167 32.7371 9.50833 32.2871 9.425C31.8371 9.325 31.3538 9.275 30.8371 9.275C30.6038 9.275 30.3204 9.3 29.9871 9.35C29.6704 9.38333 29.4288 9.425 29.2621 9.475V19.525H25.5371V7.075C26.2038 6.84167 26.9871 6.625 27.8871 6.425C28.8038 6.20833 29.8204 6.1 30.9371 6.1C31.1371 6.1 31.3788 6.11667 31.6621 6.15C31.9454 6.16667 32.2288 6.2 32.5121 6.25C32.7954 6.28333 33.0788 6.33333 33.3621 6.4C33.6454 6.45 33.8871 6.51667 34.0871 6.6L33.4621 9.675Z"
                fill="#FF9B0A"
            />
            <path
                d="M43.309 12.55C43.309 11.35 43.1507 10.5 42.834 10C42.534 9.5 42.009 9.25 41.259 9.25C41.0257 9.25 40.784 9.26667 40.534 9.3C40.284 9.31667 40.0423 9.34167 39.809 9.375V19.525H36.084V6.825C36.4007 6.74167 36.7673 6.65833 37.184 6.575C37.6173 6.475 38.0673 6.39167 38.534 6.325C39.0173 6.24167 39.509 6.18333 40.009 6.15C40.509 6.1 41.0007 6.075 41.484 6.075C42.434 6.075 43.2007 6.2 43.784 6.45C44.384 6.68333 44.8757 6.96667 45.259 7.3C45.7923 6.91667 46.4007 6.61667 47.084 6.4C47.784 6.18333 48.4257 6.075 49.009 6.075C50.059 6.075 50.9173 6.225 51.584 6.525C52.2673 6.80833 52.809 7.21667 53.209 7.75C53.609 8.28333 53.884 8.91667 54.034 9.65C54.184 10.3833 54.259 11.2 54.259 12.1V19.525H50.534V12.55C50.534 11.35 50.3756 10.5 50.059 10C49.759 9.5 49.234 9.25 48.484 9.25C48.284 9.25 48.0007 9.3 47.634 9.4C47.284 9.5 46.9923 9.625 46.759 9.775C46.8757 10.1583 46.9507 10.5667 46.984 11C47.0173 11.4167 47.034 11.8667 47.034 12.35V19.525H43.309V12.55Z"
                fill="#FF9B0A"
            />
            <path
                d="M64.6666 19.875C63.4499 19.875 62.3999 19.7083 61.5166 19.375C60.6333 19.025 59.8999 18.55 59.3166 17.95C58.7499 17.3333 58.3249 16.6083 58.0416 15.775C57.7749 14.925 57.6416 13.9917 57.6416 12.975V2.2H61.5416V12.65C61.5416 13.35 61.6166 13.95 61.7666 14.45C61.9333 14.9333 62.1499 15.3333 62.4166 15.65C62.6999 15.95 63.0333 16.1667 63.4166 16.3C63.8166 16.4333 64.2499 16.5 64.7166 16.5C65.6666 16.5 66.4333 16.2083 67.0166 15.625C67.6166 15.0417 67.9166 14.05 67.9166 12.65V2.2H71.8166V12.975C71.8166 13.9917 71.6749 14.925 71.3916 15.775C71.1083 16.625 70.6749 17.3583 70.0916 17.975C69.5083 18.575 68.7666 19.0417 67.8666 19.375C66.9666 19.7083 65.8999 19.875 64.6666 19.875Z"
                fill="#1D1D1D"
            />
            <path
                d="M80.6424 2C83.2424 2 85.2341 2.46667 86.6174 3.4C88.0007 4.31667 88.6924 5.75 88.6924 7.7C88.6924 8.91667 88.4091 9.90833 87.8424 10.675C87.2924 11.425 86.4924 12.0167 85.4424 12.45C85.7924 12.8833 86.159 13.3833 86.5424 13.95C86.9257 14.5 87.3007 15.0833 87.6674 15.7C88.0507 16.3 88.4174 16.9333 88.7674 17.6C89.1174 18.25 89.4424 18.8917 89.7424 19.525H85.3674C85.0507 18.9583 84.7257 18.3833 84.3924 17.8C84.0757 17.2167 83.7424 16.65 83.3924 16.1C83.059 15.55 82.7257 15.0333 82.3924 14.55C82.059 14.05 81.7257 13.6 81.3924 13.2H79.4674V19.525H75.5674V2.45C76.4174 2.28333 77.2924 2.16667 78.1924 2.1C79.109 2.03333 79.9257 2 80.6424 2ZM80.8674 5.325C80.5841 5.325 80.3257 5.33333 80.0924 5.35C79.8757 5.36667 79.6674 5.38333 79.4674 5.4V10.1H80.5674C82.034 10.1 83.0841 9.91667 83.7174 9.55C84.3507 9.18333 84.6674 8.55833 84.6674 7.675C84.6674 6.825 84.3424 6.225 83.6924 5.875C83.059 5.50833 82.1174 5.325 80.8674 5.325Z"
                fill="#1D1D1D"
            />
            <path d="M103.817 16.175V19.525H92.2422V2.2H96.1422V16.175H103.817Z" fill="#1D1D1D" />
        </svg>
    );
}

export default LogoIcon;
