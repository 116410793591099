import { cn } from '@app/shadcn/util/lib';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@radix-ui/react-collapsible';
import { CircleMinus, LucideIcon } from 'lucide-react';
import React from 'react';

export function FAQCollapsibleComponent({ faq, className = '', triggerIcon }: { faq: { title: string; content: string }; className?: string; triggerIcon?: LucideIcon }) {
    const [isOpen, setIsOpen] = React.useState(false);
    const Component = triggerIcon ? triggerIcon : CircleMinus;

    return (
        <Collapsible open={isOpen} onOpenChange={setIsOpen} className={cn('w-full rounded-2xl bg-secondary-grey-gradient', isOpen ? 'shadow-faq-section' : '', className)}>
            <CollapsibleTrigger asChild>
                <div className={cn('flex w-full cursor-pointer flex-row justify-between rounded-2xl border-gray-200 p-4', isOpen ? 'rounded-b-none border-b' : '')}>
                    <span className={cn(isOpen ? 'body-20-bold' : 'body-20-regular')}>{faq.title}</span>
                    <Component className={cn('h-6 w-6 cursor-pointer', isOpen ? 'rotate-180' : '')} />
                </div>
            </CollapsibleTrigger>
            <CollapsibleContent>
                <div className="body-20-regular rounded-b-2xl border border-gray-200 bg-gray-100 p-4 text-gray-500">{faq.content}</div>
            </CollapsibleContent>
        </Collapsible>
    );
}
