import FacebookIcon from '@app/components/icons/share/facebook-icon';
import LinkedInIcon from '@app/components/icons/share/linkedIn-icon';
import TelegramIcon from '@app/components/icons/share/telegram-icon';
import TwitterIcon from '@app/components/icons/share/twitter-icon';

const SocailShareSection = () => {
    const SocialShareOptions = [
        {
            title: 'Facebook',
            component: FacebookIcon
        },
        {
            title: 'LinkedIn',
            component: LinkedInIcon
        },
        {
            title: 'Twitter',
            component: TwitterIcon
        },
        {
            title: 'Telegram',
            component: TelegramIcon
        }
    ];
    return (
        <div className="flex flex-row flex-wrap items-center gap-2 md:gap-6">
            {SocialShareOptions.map((social) => {
                return (
                    <div className="flex flex-col gap-2 lg:gap-3" key={social.title}>
                        <div className="flex justify-center rounded-lg bg-secondary-grey-gradient p-3 shadow-secondary-grey-button lg:p-5">{<social.component className="h-3 w-3 lg:h-6 lg:w-6" />}</div>
                        <h1 className="body-14-medium text-gray-500">{social.title}</h1>
                    </div>
                );
            })}
        </div>
    );
};

export default SocailShareSection;
