import React from 'react';

export const SupportIcon = ({ props }: { props?: React.SVGAttributes<any> }) => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12 16.3613V12.3613M12 8.36133H12.01M22 12.3613C22 17.8842 17.5228 22.3613 12 22.3613C6.47715 22.3613 2 17.8842 2 12.3613C2 6.83848 6.47715 2.36133 12 2.36133C17.5228 2.36133 22 6.83848 22 12.3613Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
