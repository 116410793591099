import TextInput from '@app/components/common/input/TextInput';
import AnchorLink from '@app/components/common/links/anchor-link';
import LogoIcon from '@app/components/icons/logo';
import { useScrollContext } from '@app/context/scroll-context';
import { Button } from '@app/shadcn/components/ui/button';
import { Separator } from '@app/shadcn/components/ui/separator';
import { useToast } from '@app/shadcn/hooks/use-toast';
import { useSubscribeMutation } from '@app/store/formUrl/api';
import { useRouter } from 'next/router';
import { ChangeEvent, useState } from 'react';
import LandingPageLaout from './landingPage';

export default function Footer() {
    const router = useRouter();
    const currentPath = router.pathname;

    if (currentPath === '/login' || currentPath === '/verify-otp') {
        return null;
    }

    return (
        <div className="bg-footer flex h-full flex-col items-center border-3 border-primary/30 bg-white bg-cover bg-center bg-no-repeat lg:bg-left-top">
            <LandingPageLaout>
                <FooterSectionContent />
            </LandingPageLaout>
        </div>
    );
}

const FooterSectionContent = () => {
    const router = useRouter();
    const { howItWorksRef, featuresRef, faqsRef, scrollToSection } = useScrollContext();

    const { toast } = useToast();

    const handleNavigation = (ref: React.RefObject<HTMLDivElement>) => {
        if (router.query.page !== undefined) {
            router.replace('/');
        }
        scrollToSection(ref);
    };

    const [subscribe, { isLoading }] = useSubscribeMutation();
    const [emailAddress, setEmailAddress] = useState<string | null>(null);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmailAddress(event.target.value);
    };

    const handleSubscribe = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (emailAddress) {
            await subscribe({ emailAddress }).then((response) => {
                if ('data' in response) {
                    toast({ title: 'Thank you for subscribtion' });
                    setEmailAddress(null);
                } else {
                    toast({ title: 'Failed on subscription', variant: 'destructive' });
                }
            });
        }
    };

    return (
        <div className="flex h-full w-full flex-col gap-6">
            <div className="flex flex-col justify-between gap-4 md:flex-row">
                <AnchorLink href="/" aria-label="navigate to landing page">
                    <LogoIcon />
                </AnchorLink>
                <div className="flex flex-row gap-8 font-semibold leading-[24px] tracking-[-0.18px]">
                    <h1 onClick={() => handleNavigation(howItWorksRef)} className="cursor-pointer">
                        How it Works
                    </h1>
                    <h1 onClick={() => handleNavigation(featuresRef)} className="cursor-pointer">
                        Features
                    </h1>
                    <h1 className="cursor-pointer" onClick={() => handleNavigation(faqsRef)}>
                        FAQs
                    </h1>
                </div>
            </div>
            <Separator />
            <div className="my-2 flex h-full w-full flex-col justify-between gap-8 md:flex-row">
                <div className="flex max-w-[443px] flex-col gap-3">
                    <h1 className="text-xs font-semibold text-gray-500">NEWSLETTER</h1>
                    <h1 className="heading-28-bold">Customize and personalize your form URL.</h1>
                    <h1 className="text-gray-500">No more wondering and subscribe and be the first to receive all the recent updates, articles, and valuable materials</h1>
                </div>
                <div className="flex min-h-full w-full flex-row items-center justify-end gap-2">
                    <TextInput onChange={handleOnChange} placeholder="Email Address" className="w-full md:w-fit" />
                    <Button onClick={(e) => handleSubscribe(e)}>Subscribe for free</Button>
                </div>
            </div>
            <Separator />
            <div className="flex w-full flex-col justify-between gap-6 text-sm md:flex-row">
                <p className="">&copy; 2024 Sireto Technology. All rights reserved.</p>
                <div className="flex items-center gap-2 text-gray-500 sm:gap-6">
                    <AnchorLink target="_blank" href="https://docs.formurl.com/privacy-policy/">
                        Privacy Policy
                    </AnchorLink>
                    <AnchorLink target="_blank" href="https://docs.formurl.com/terms-of-service/">
                        Terms of Services
                    </AnchorLink>
                </div>
            </div>
        </div>
    );
};
