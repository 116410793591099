import { howItWorks } from '@app/constants';
import { useScrollContext } from '@app/context/scroll-context';
import LandingPageLaout from '@app/layouts/landingPage';
import { Step1Section, Step2Section, Step3Section } from '../pages/landingPage/HowItWorks';

export default function HowItWorks() {
    const { howItWorksRef } = useScrollContext();
    return (
        <div ref={howItWorksRef} className="bg-how-it-works flex flex-col items-center bg-cover bg-no-repeat">
            <LandingPageLaout className="">
                <div className="flex flex-col gap-3 text-center md:w-[508px]">
                    <p className="heading-40-bold">{howItWorks.title}</p>
                    <p className="body-18-regular text-gray-500">{howItWorks.description}</p>
                </div>
                <div className="flex flex-col gap-6">
                    <div className="grid h-full w-full grid-cols-1 justify-center gap-6 md:grid-cols-2">
                        <Step1Section />
                        <Step2Section />
                    </div>
                    <Step3Section />
                </div>
            </LandingPageLaout>
        </div>
    );
}



