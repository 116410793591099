import GoogleformIcon from '@app/components/icons/googleform';

const FormPreviewHeader = () => {
    return (
        <div id="google-form-preview-header" className="flex h-8 flex-col gap-[6px] w-full bg-white px-2 pt-2">
            <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row items-center justify-between gap-[2px]">
                    <GoogleformIcon className="h-2 w-2" />
                    <h1 className="text-[6px] font-bold">Documentation</h1>
                    <CircularDot />
                </div>
                <div className="flex flex-row items-center gap-1">
                    {[...Array.from({ length: 4 })].map((_, i) => {
                        return <CircularDot key={i} />;
                    })}
                    <div className="h-2 w-6 rounded-3xl bg-gray-300" />
                    {[...Array.from({ length: 2 })].map((_, i) => {
                        return <CircularDot key={i} />;
                    })}
                </div>
            </div>
            <div className="flex justify-center gap-1">
                <div className="border-b border-gray-500 text-[5px]">Questions</div>
                <div className="text-[5px] text-gray-500">Responses</div>
                <div className="text-[5px] text-gray-500">Settings</div>
            </div>
        </div>
    );
};

const FormPreviewBody = () => {
    return (
        <div id="google-form-preview-body" className="flex w-2/3 flex-col items-center gap-2 pt-3">
            <div className="flex max-w-[190px] flex-col gap-1 rounded-[3px] border-t border-t-gray-500 bg-white px-[5px] py-[6px]">
                <h1 className="text-[6px] font-bold">Contact Information</h1>
                <h1 className="text-[5px] text-gray-500">A job application form is a frequently used data collection tool to collect, organize and track applications for job adverts.</h1>
            </div>
            <div className="flex w-full max-w-[190px] flex-col gap-1 rounded-[3px] bg-white px-[5px] py-[6px]">
                <h1 className="text-[6px] font-medium text-gray-700">Name</h1>
                <h1 className="w-1/2 border-b border-gray-200 pb-1 text-[5px] text-gray-500">Short answer text</h1>
            </div>
        </div>
    );
};

const HeroSecttionRightPanelFormPreview = () => {
    return (
        <div className="flex h-full flex-col items-center gap-4 rounded-t-3xl border-t border-hero-sectionBorder bg-white pt-2 shadow-lighterShadow md:pt-6">
            <h1 className="body-18-bold">Preview</h1>
            <div className="flex h-full w-1/2 min-w-fit flex-col items-center rounded-t-[4px] border border-gray-200 bg-gray-100 lg:w-2/3 lg:min-w-[300px]">
                <FormPreviewHeader />
                <FormPreviewBody />
            </div>
        </div>
    );
};

const CircularDot = ({ size = 6 }: { size?: number }) => {
    return <div className="rounded-full bg-gray-200" style={{ height: size, width: size }} />;
};

export default HeroSecttionRightPanelFormPreview;
