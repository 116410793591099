import LandingPageLaout from '@app/layouts/landingPage';
import { HeroSectionLeftPanel } from '../pages/landingPage/HeroSection/LeftPanel';
import { HeroSectionRightPanelCustomizeLink } from '../pages/landingPage/HeroSection/RightPanelCustomizeLink';
import HeroSecttionRightPanelFormPreview from '../pages/landingPage/HeroSection/RightPanelFormPreview';

export default function HeroSection() {
    return (
        <div className="flex h-full justify-center bg-gray-50 bg-hero-section bg-cover bg-right-top bg-no-repeat">
            <LandingPageLaout className={'justify-center px-5 pt-[72px] md:flex-row md:gap-0 md:pl-[130px] md:pr-[106px] !pb-0'}>
                <div className="flex flex-col items-end md:h-full md:w-1/2 md:pt-8">
                    <div className="hidden h-2 w-full flex-row items-center md:flex">
                        <div className="h-3 w-3 rounded-full bg-yellow-500" />
                        <div className="w-full border border-dashed" />
                    </div>
                    <HeroSectionLeftPanel />
                </div>
                <div className="flex h-full w-full flex-col gap-2 overflow-hidden rounded-t-[32px] bg-gray-200/40 px-5 pt-5 shadow-hero-section backdrop-blur-6 md:w-1/2 md:gap-8 md:px-5 md:pt-5">
                    <HeroSectionRightPanelCustomizeLink />
                    <HeroSecttionRightPanelFormPreview />
                </div>
            </LandingPageLaout>
        </div>
    );
}




