import { CustomizeLinkIcon } from "@app/components/icons/dashboard";
import { cn } from "@app/shadcn/util/lib";
import { IFeatureTab, TabTypes } from "./TabPanel";

const TabPanelHeader = ({ tab, handleOnClick }: { tab: IFeatureTab & { isTabSelected: boolean }; handleOnClick: (type: TabTypes) => void }) => {
    return (
        <div className={cn('flex cursor-pointer items-center gap-2 rounded-xl border-2 border-gray-200 p-3 shadow-secondary-grey-button', tab.isTabSelected ? 'bg-secondary text-white border-white shadow-none' : '')} onClick={() => handleOnClick(tab.type)}>
            <CustomizeLinkIcon />
            <h1>{tab.label}</h1>
        </div>
    );
};


export default TabPanelHeader