import { useLoginContext } from '@app/context/login-provider';
import { useScrollContext } from '@app/context/scroll-context';
import { Button } from '@app/shadcn/components/ui/button';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import { useRouter } from 'next/router';

export const HeroSectionLeftPanel = () => {
    const router = useRouter();
    const { customizeLinkRef, scrollToSection } = useScrollContext();
    const { loginContext, setLoginContext } = useLoginContext();

    const handleClickSignIn = () => {
        setLoginContext({ ...loginContext, redirectFromLandingPage: false });
        router.push('/login');
    };

    return (
        <div className="flex flex-col items-center gap-6 text-center md:items-start md:pr-10 md:pt-6 md:text-start">
            <h1 className="heading-56-bold md:mb-6">Customize your Form Link to match your Brand</h1>
            <h1 className="body-18-regular text-gray-500">Personalize your form URL to reflect your brand identity.</h1>
            <div className="flex gap-4">
                <Button className="w-fit" onClick={() => scrollToSection(customizeLinkRef)}>
                    Start for Free
                </Button>
                <Button className="w-fit" variant={'secondary-grey'} onClick={handleClickSignIn}>
                    Sign In
                </Button>
            </div>
            <div className="flex flex-row items-center gap-2">
                <InfoCircledIcon className="h-4 w-4 text-blue-500" />
                <h1 className="text-sm leading-[20px] tracking-[-0.09px]">No registration required. Start customizing now!</h1>
            </div>
        </div>
    );
};
