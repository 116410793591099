import Step1 from '@app/assets/image/howItWorks/step1.png';
import { howItWorks } from '@app/constants';
import Image from 'next/image';

const Step1Section = () => {
    return (
        <div id="step-1-section" className="flex h-full w-full flex-col gap-2 rounded-[32px] border border-gray-200 bg-white p-3 shadow-how-it-works-card">
            <div className="flex flex-row gap-6 p-4">
                <div className="heading-24-bold flex h-[50px] w-[50px] items-center justify-center rounded-full border-2 border-secondary-500 p-3 text-secondary-500">1</div>
                <div className="flex flex-col gap-1">
                    <h1 className="body-20-bold">{howItWorks.steps[0].title}</h1>
                    <h1 className="body-16-regular text-gray-500">{howItWorks.steps[0].description}</h1>
                </div>
            </div>
            <div className="h-full w-full rounded-3xl bg-how-it-works-card-1 pl-8 pt-[30px] lg:pl-14">
                <div className="relative -bottom-6 flex flex-col gap-3 rounded-l-[20px] bg-how-it-works-card-1-gradient p-[30px] pb-0 pr-0">
                    <h1 className="body-16-semibold text-white">Form Link</h1>
                    <div className="flex flex-row items-center gap-2 rounded-l-xl bg-white p-3">
                        <h1 className="text-xs text-gray-500">https://docs.google.com/forms/</h1>
                        <div className="h-3 w-full rounded-3xl bg-secondary-300" />
                    </div>
                </div>
                <Image src={Step1} alt="step-1" className="relative -right-2/3 z-10 h-[145px] w-fit" />
            </div>
        </div>
    );
};

export default Step1Section;
