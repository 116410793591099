import React from 'react';

export const DashboardIcon = ({ props }: { props?: React.SVGAttributes<any> }) => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M9 3.36133H4C3.44772 3.36133 3 3.80904 3 4.36133V11.3613C3 11.9136 3.44772 12.3613 4 12.3613H9C9.55228 12.3613 10 11.9136 10 11.3613V4.36133C10 3.80904 9.55228 3.36133 9 3.36133Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20 3.36133H15C14.4477 3.36133 14 3.80904 14 4.36133V7.36133C14 7.91361 14.4477 8.36133 15 8.36133H20C20.5523 8.36133 21 7.91361 21 7.36133V4.36133C21 3.80904 20.5523 3.36133 20 3.36133Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20 12.3613H15C14.4477 12.3613 14 12.809 14 13.3613V20.3613C14 20.9136 14.4477 21.3613 15 21.3613H20C20.5523 21.3613 21 20.9136 21 20.3613V13.3613C21 12.809 20.5523 12.3613 20 12.3613Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9 16.3613H4C3.44772 16.3613 3 16.809 3 17.3613V20.3613C3 20.9136 3.44772 21.3613 4 21.3613H9C9.55228 21.3613 10 20.9136 10 20.3613V17.3613C10 16.809 9.55228 16.3613 9 16.3613Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
