import Step3 from '@app/assets/image/howItWorks/step3.png';
import { howItWorks } from '@app/constants';
import Image from 'next/image';
import SocailShareSection from './SocialShareSection';

const Step3Section = () => {
    return (
        <div id="step-3-section" className="flex w-full flex-col gap-2 rounded-[32px] border border-gray-200 bg-white p-3 shadow-how-it-works-card">
            <div className="flex flex-row gap-6 p-4">
                <div className="heading-24-bold flex h-[50px] w-[50px] items-center justify-center rounded-full border-2 border-secondary-500 p-3 text-secondary-500">3</div>
                <div className="flex flex-col gap-1">
                    <h1 className="body-20-bold">{howItWorks.steps[2].title}</h1>
                    <h1 className="body-16-regular text-gray-500">{howItWorks.steps[2].description}</h1>
                </div>
            </div>
            <div className="relative flex h-full w-full justify-center rounded-3xl bg-how-it-works-card-3 p-8 lg:p-10">
                <div className="flex h-full w-full flex-col gap-6 rounded-[20px] bg-white p-4 px-6 shadow-lighterShadow lg:flex-row lg:gap-16 lg:p-8">
                    <div className="flex w-full flex-col gap-7">
                        <div className="flex flex-row justify-between gap-8">
                            <div className="h-4 w-10/12 rounded-3xl bg-gray-100 lg:h-8 lg:w-full" />
                            <div className="h-4 w-4 rounded-full bg-gray-200 lg:h-8 lg:w-8" />
                        </div>
                        <SocailShareSection />
                    </div>
                    <div className="flex h-full w-full flex-col gap-4">
                        <div className="flex flex-row justify-between gap-8">
                            <div className="h-4 w-10/12 rounded-3xl bg-gray-100 lg:h-8 lg:w-full" />
                            <div className="h-4 w-4 rounded-full bg-gray-200 lg:h-8 lg:w-8" />
                        </div>
                        <div className="flex w-11/12 items-center justify-between rounded-lg bg-gray-50 p-1 lg:w-full lg:px-3 lg:py-4">
                            <h1 className="body-14-regular w-full basis-3/4 truncate text-gray-500">https://formurl.com/to/myshort-form</h1>
                            <div className="flex w-full basis-1/4 justify-center rounded-lg bg-blue-700 px-2 py-[6px] text-xs text-white md:text-sm">
                                <h1>Copy</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <Image alt="step-3" className="absolute bottom-0 right-0 h-20 w-20 rounded-b-3xl lg:w-fit xl:h-1/2" src={Step3} />
            </div>
        </div>
    );
};

export default Step3Section;
