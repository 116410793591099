import CustomizeUrl from '@app/components/sections/customize-url';
import FAQ from '@app/components/sections/faq';
import Features from '@app/components/sections/feature';
import HeroSection from '@app/components/sections/hero-section';
import HowItWorks from '@app/components/sections/how-it-work';
import { useBrowserFingerPrintContext } from '@app/context/browser-fingerprint';
import Layout from '@app/layouts/_layout';
import { useGetUserStatusQuery } from '@app/store/users/api';
import { getFingerprint } from '@app/utils/browserFingerprint';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function Home() {
    const { setBrowserFingerPrint } = useBrowserFingerPrintContext();
    const router = useRouter();

    const { data: user, isSuccess: isUserStatusSuccess } = useGetUserStatusQuery();

    // useEffect(() => {
    //     if (isUserStatusSuccess) {
    //         router.push('/dashboard');
    //     }
    // }, [user]);

    useEffect(() => {
        getFingerprint()
            .then((fingerPrint) => {
                setBrowserFingerPrint(fingerPrint);
            })
            .catch((err) => console.error('BrowserFingerPrintError: ', err));
    }, []);

    return (
        <Layout>
            <main className="flex h-full w-full flex-col">
                <HeroSection />
                <HowItWorks />
                <CustomizeUrl />
                <Features />
                <FAQ />
            </main>
        </Layout>
    );
}
